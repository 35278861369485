.country-flag {
    height: 1em;
}

.ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.ant-dropdown-trigger {
    display: flex;
}

.language-icon {
    font-size: 0.9rem;
    cursor: pointer;
    align-items: center;

    .language-text {
        margin-left: 0.5em;
        color: black;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        svg {
        margin-left: 0.2em;
        }

        &--light {
        color: white !important;
        }
    }
}