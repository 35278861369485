.hero {
  background-image: url("../../../../public/images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

.hero .container {
  padding-top: 15%;
}

.hero h1 {
  font-size: 60px;
}

.form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
}

.box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
  }
  .form .ant-form-item {
    flex: 1 1 100%;
    margin-right: 0;
  }
  .ant-picker-dropdown {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media screen and (max-height: 800px) {
  .hero {
    height: 105vh;
  }
}
