/* Custom styles for the carousel arrows */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  width: 30px;
  height: 30px;
  z-index: 2;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-carousel .slick-prev {
  left: 10px; /* Position inside the image */
}

.ant-carousel .slick-next {
  right: 10px; /* Position inside the image */
}

.ant-carousel .slick-prev::before,
.ant-carousel .slick-next::before {
  font-size: 20px;
  color: white; 
  opacity: 1;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  background: #27ae60;
}

/* Image styling for carousel */
.carousel-image {
  width: 100%;
  height: 400px; 
  object-fit: cover;
}

/* Modal styling */
.custom-modal {
  width: 800px !important; 
  top: 20px !important; 

  .ant-modal-body {
    padding: 0 !important; 
  }

  img {
    width: 100%;
    height: auto;
  }
}

/* Row styling */
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.row > div {
  padding: 10px;
}

/* Column styling for responsive design */
.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
  }

  .form .ant-form-item {
    flex: 1 1 100%;
    margin-right: 0;
  }

  .ant-picker-dropdown {
    width: 200% !important;
    left: 0 !important;
    right: 0 !important;
  }
  
  /* Additional styles for Date Picker within modal */
  .ant-modal .ant-picker-dropdown {
    position: static !important;
    display: block !important;
    width: 100% !important;
    transform: none !important;
    margin-top: 10px;
  }
}

/* Text styling */
.text-uppercase {
  text-transform: uppercase;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.fw-bolder {
  font-weight: bolder;
}

.display-6 {
  font-size: 2rem;
  font-weight: 400;
}

.fw-bold {
  font-weight: bold;
}

.ms-2 {
  margin-left: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/* Skeleton loading styling */
.skeleton {
  background-color: #eee;
  border-radius: 4px;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.skeleton-text {
  height: 1em;
  margin-bottom: 0.5em;
}

.skeleton-rect {
  height: 200px;
}

/* Form styling */
.form.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Additional styling for the content */
.container.py-5 {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
}

.row.py-4 {
  align-items: center;
}

h4 {
  color: #666;
}

button {
  cursor: pointer;
}

.show-more {
  text-align: center;
}

.show-more-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
  display: block;
  margin: 20px auto;

  &:hover {
    background-color: #1e9b4d;
  }
}

.carousel-image {
  width: 100%;
  height: 400px; 
  object-fit: cover;
}
