$primaryColor: #27ae60;

.loading-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}

.container {
  max-width: 80%;
  margin: auto;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
}

button {
  border-radius: 5px;
  padding: 17px 30px;
  background: $primaryColor;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

button i {
  margin-right: 5px;
}

textarea,
input {
  border: none;
  outline: none;
  background: none;
}

.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

label {
  color: #2d3954;
  font-size: 14px;
}

.mtop {
  margin-top: 50px;
}

h4 {
  font-weight: 500;
  margin-top: 10px;
}

.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}

.padding {
  padding: 80px 0;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn2 {
  border-radius: 50px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.btn3 {
  background: #ff6922;
  border-radius: 50px;
}

.btn4 {
  background-color: #000;
  color: #fff;
}

.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: $primaryColor;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid $primaryColor;
  background: #fff;
}

.back {
  height: 40vh;
  position: relative;
}

.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: 1;
}

.back .container {
  color: #fff;
  padding: 120px 0;
}

.back h1 {
  font-size: 40px;
  font-weight: 500;
}

.mb {
  margin-bottom: 80px;
}

.ant-btn-primary {
  background-color: $primaryColor;
  &:hover {
    background-color: lighten($primaryColor, 10%) !important;
    border-color: lighten($primaryColor, 10%) !important;
  }
}

.ant-menu-item-selected,
.ant-menu.ant-menu-horizontal,
.ant-menu-item-selected, .ant-menu-item-active {
  color: $primaryColor !important;
  &:after {
    border-bottom: 2px solid $primaryColor !important;
  }
}

@media (max-width: 992px) {
  .grid3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .grid4,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    width: 100%;
  }

  .container {
    max-width: 90%;
  }
  
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
