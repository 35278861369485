.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 4;
  position: sticky;
  background-color: #fff;

  .logo-image {
    width: 50px;
    margin-top: 1.5rem;
  }

  .desktop-menu {
    flex-grow: 1;
    justify-content: center;
  }

  .mobile-menu-button {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop-menu {
      display: none;
    }

    .mobile-menu-button {
      display: inline-block;
      order: -1;
    }

    .logo-image {
      order: 0;
    }
  }
}
