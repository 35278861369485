.room {
  & .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  & .box {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    &:hover {
      transform: translateY(-10px);
    }

    & .carousel-img {
      width: 100%;
      height: 280px;
      object-fit: cover;
    }

    & .text {
      padding: 20px;
    }

    & .category span {
      padding: 4px 15px;
      border-radius: 2px;
      font-weight: 600;
      font-size: 13px;
      display: inline-block;
    }

    & .category i {
      font-size: 20px;
      color: #bec7d8;
    }

    & p {
      color: #72809d;
    }

    & .button {
      border-top: 1px solid #e4e8f3;
      padding: 10px 20px; 
    }
  }
}

.icons {
  padding-left: 2.5em;
}

.show-rooms-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
  display: block;
  margin: 20px auto;

  &:hover {
    background-color: #1e9b4d;
  }
}

.button-container {
  text-align: center;
}

.room-container {
  min-height: 15vh;
  max-height: 120vh;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(transparent, white);
    transition: opacity 0.3s ease;
  }

  &.show-all::after {
    opacity: 0;
    pointer-events: none;
  }
}
