.heading {
    text-align: center;
    width: 60%;
    margin: auto;
}

.heading h1{
    color: #2d3954;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 60px;
    font-family: "Poppins", sans-serif;
}

.heading p {
    color: #3a4c6e;
    font-size: large;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: 5% 0;
}